export const CiteprocToRcMap = {
  "article-journal": {
    "rc_type": "article",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "container-title": "article.journal",
      "ISSN": "article.issn",
      "DOI": "ext_ids.doi",
      "PMID": "ext_ids.pmid",
      "PMCID": "ext_ids.pmcid",
      "arXiv": "ext_ids.arxiv",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "journalAbbreviation": "article.journal_abbrev",
      "title-short": "custom_metadata.title_short",
      "editor": "custom_metadata.editor",
      "translator": "custom_metadata.translator",
      "reviewed-author": "custom_metadata.reviewed_author",
      "collection-title": "custom_metadata.container_title",
      "page": "article.pagination",
      "issue": "article.issue",
      "volume": "article.volume",
      "language": "custom_metadata.language",
      "archive": "custom_metadata.archive",
      "archive_location": "custom_metadata.archive_location",
      "source": "custom_metadata.source",
      "call-number": "custom_metadata.call_number",
      "note": "user_data.notes"
    }
  },
  "broadcast": {
    "rc_type": "radio_broadcast",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "container-title": "custom_metadata.container_title",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "title-short": "custom_metadata.title_short",
      "publisher": "custom_metadata.publisher",
      "publisher-place": "custom_metadata.event_place",
      "language": "custom_metadata.language",
      "archive": "custom_metadata.archive",
      "archive_location": "custom_metadata.archive_location",
      "source": "custom_metadata.source",
      "call-number": "custom_metadata.call_number",
      "note": "user_data.notes",
      "number": "custom_metadata.number",
      "dimensions": "custom_metadata.dimensions",
      "medium": "custom_metadata.medium"
    }
  },
  "entry-dictionary": {
    "rc_type": "dictionary_entry",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "editor": "custom_metadata.editor",
      "collection-editor": "custom_metadata.series_editor",
      "translator": "custom_metadata.translator",
      "container-title": "custom_metadata.container_title",
      "URL": "custom_metadata.url",
      "ISBN": "article.isbn",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "page": "article.pagination",
      "volume": "article.volume",
      "number-of-volumes": "custom_metadata.number_of_volumes",
      "title-short": "custom_metadata.title_short",
      "collection-title": "article.journal",
      "collection-number": "custom_metadata.collection_number",
      "publisher": "custom_metadata.publisher",
      "publisher-place": "custom_metadata.event_place",
      "language": "custom_metadata.language",
      "archive": "custom_metadata.archive",
      "archive_location": "custom_metadata.archive_location",
      "source": "custom_metadata.source",
      "call-number": "custom_metadata.call_number",
      "note": "user_data.notes",
      "edition": "custom_metadata.edition"
    }
  },
  "entry-encyclopedia": {
    "rc_type": "encyclopedia_article",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "editor": "custom_metadata.editor",
      "collection-editor": "custom_metadata.series_editor",
      "translator": "custom_metadata.translator",
      "container-title": "custom_metadata.container_title",
      "URL": "custom_metadata.url",
      "ISBN": "article.isbn",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "page": "article.pagination",
      "volume": "article.volume",
      "number-of-volumes": "custom_metadata.number_of_volumes",
      "title-short": "custom_metadata.title_short",
      "collection-title": "article.journal",
      "collection-number": "custom_metadata.collection_number",
      "publisher": "custom_metadata.publisher",
      "publisher-place": "custom_metadata.event_place",
      "language": "custom_metadata.language",
      "archive": "custom_metadata.archive",
      "archive_location": "custom_metadata.archive_location",
      "source": "custom_metadata.source",
      "call-number": "custom_metadata.call_number",
      "note": "user_data.notes",
      "edition": "custom_metadata.edition"
    }
  },
  "manuscript": {
    "rc_type": "manuscript",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "translator": "custom_metadata.translator",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "number-of-pages": "article.number_of_pages",
      "title-short": "custom_metadata.title_short",
      "publisher-place": "custom_metadata.event_place",
      "language": "custom_metadata.language",
      "genre": "custom_metadata.genre",
      "archive": "custom_metadata.archive",
      "archive_location": "custom_metadata.archive_location",
      "source": "custom_metadata.source",
      "call-number": "custom_metadata.call_number",
      "note": "user_data.notes",
    }
  },
  "personal_communication": {
    "rc_type": "letter",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "recipient": "custom_metadata.recipient",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "title-short": "custom_metadata.title_short",
      "language": "custom_metadata.language",
      "genre": "custom_metadata.genre",
      "archive": "custom_metadata.archive",
      "archive_location": "custom_metadata.archive_location",
      "source": "custom_metadata.source",
      "call-number": "custom_metadata.call_number",
      "note": "user_data.notes",
    }
  },
  "post": {
    "rc_type": "forum_post",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "container-title": "custom_metadata.container_title",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "title-short": "custom_metadata.title_short",
      "language": "custom_metadata.language",
      "genre": "custom_metadata.genre",
      "note": "user_data.notes",
    }
  },
  "post-weblog": {
    "rc_type": "blog_post",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "container-title": "custom_metadata.container_title",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "title-short": "custom_metadata.title_short",
      "language": "custom_metadata.language",
      "genre": "custom_metadata.genre",
      "note": "user_data.notes",
    }
  },
  "graphic": {
    "rc_type": "artwork",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "recipient": "custom_metadata.recipient",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "title-short": "custom_metadata.title_short",
      "language": "custom_metadata.language",
      "genre": "custom_metadata.genre",
      "archive": "custom_metadata.archive",
      "archive_location": "custom_metadata.archive_location",
      "source": "custom_metadata.source",
      "call-number": "custom_metadata.call_number",
      "note": "user_data.notes",
      "dimensions": "custom_metadata.dimensions",
      "medium": "custom_metadata.medium"
    }
  },
  "song": {
    "rc_type": "audio_recording",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "URL": "custom_metadata.url",
      "ISBN": "article.isbn",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "volume": "article.volume",
      "number-of-volumes": "custom_metadata.number_of_volumes",
      "title-short": "custom_metadata.title_short",
      "collection-title": "article.journal",
      "publisher": "custom_metadata.publisher",
      "publisher-place": "custom_metadata.event_place",
      "language": "custom_metadata.language",
      "genre": "custom_metadata.genre",
      "archive": "custom_metadata.archive",
      "archive_location": "custom_metadata.archive_location",
      "source": "custom_metadata.source",
      "call-number": "custom_metadata.call_number",
      "note": "user_data.notes",
      "dimensions": "custom_metadata.dimensions",
      "medium": "custom_metadata.medium"
    }
  },
  "bill": {
    "rc_type": "bill",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "page": "article.pagination",
      "volume": "article.volume",
      "title-short": "custom_metadata.title_short",
      "container-title": "custom_metadata.container_title",
      "language": "custom_metadata.language",
      "note": "user_data.notes",
      "section": "custom_metadata.section",
      "chapter-number": "custom_metadata.chapter_number",
      "references": "custom_metadata.references",
      "authority": "custom_metadata.authority"
    }
  },
  "book": {
    "rc_type": "book",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "editor": "custom_metadata.editor",
      "collection-editor": "custom_metadata.series_editor",
      "translator": "custom_metadata.translator",
      "ISBN": "article.isbn",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "number-of-pages": "article.number_of_pages",
      "volume": "article.volume",
      "number-of-volumes": "custom_metadata.number_of_volumes",
      "title-short": "custom_metadata.title_short",
      "collection-title": "article.journal",
      "collection-number": "custom_metadata.collection_number",
      "publisher": "custom_metadata.publisher",
      "publisher-place": "custom_metadata.event_place",
      "language": "custom_metadata.language",
      "archive": "custom_metadata.archive",
      "archive_location": "custom_metadata.archive_location",
      "source": "custom_metadata.source",
      "call-number": "custom_metadata.call_number",
      "note": "user_data.notes",
      "edition": "custom_metadata.edition",
      "DOI": "ext_ids.doi"
    }
  },
  "chapter": {
    "rc_type": "book_section",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "editor": "custom_metadata.editor",
      "collection-editor": "custom_metadata.series_editor",
      "translator": "custom_metadata.translator",
      "container-author": "custom_metadata.book_author",
      "container-title": "custom_metadata.container_title",
      "ISBN": "article.isbn",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "page": "article.pagination",
      "volume": "article.volume",
      "number-of-volumes": "custom_metadata.number_of_volumes",
      "title-short": "custom_metadata.title_short",
      "collection-title": "article.journal",
      "collection-number": "custom_metadata.collection_number",
      "publisher": "custom_metadata.publisher",
      "publisher-place": "custom_metadata.event_place",
      "language": "custom_metadata.language",
      "archive": "custom_metadata.archive",
      "archive_location": "custom_metadata.archive_location",
      "source": "custom_metadata.source",
      "call-number": "custom_metadata.call_number",
      "note": "user_data.notes",
      "edition": "custom_metadata.edition",
      "DOI": "ext_ids.doi"
    }
  },
  "legal_case": {
    "rc_type": "case",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "page": "article.pagination",
      "volume": "article.volume",
      "title-short": "custom_metadata.title_short",
      "language": "custom_metadata.language",
      "note": "user_data.notes",
      "number": "custom_metadata.number",
      "authority": "custom_metadata.authority",
      "container-title": "custom_metadata.container_title",
      "references": "custom_metadata.references"
    }
  },
  "paper-conference": {
    "rc_type": "conference_paper",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "container-title": "custom_metadata.container_title",
      "work-type": "custom_metadata.work_type",
      "ISBN": "article.isbn",
      "DOI": "ext_ids.doi",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "page": "article.pagination",
      "volume": "article.volume",
      "title-short": "custom_metadata.title_short",
      "collection-title": "article.journal",
      "publisher": "custom_metadata.publisher",
      "publisher-place": "custom_metadata.event_place",
      "language": "custom_metadata.language",
      "archive": "custom_metadata.archive",
      "archive_location": "custom_metadata.archive_location",
      "source": "custom_metadata.source",
      "call-number": "custom_metadata.call_number",
      "note": "user_data.notes",
      "event": "custom_metadata.event"
    }
  },
  "article": {
    "rc_type": "document",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "journalAbbreviation": "article.journal_abbrev",
      "title-short": "custom_metadata.title_short",
      "publisher": "custom_metadata.publisher",
      "language": "custom_metadata.language",
      "archive": "custom_metadata.archive",
      "archive_location": "custom_metadata.archive_location",
      "source": "custom_metadata.source",
      "call-number": "custom_metadata.call_number",
      "note": "user_data.notes"
    }
  },
  "motion_picture": {
    "rc_type": "film",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "title-short": "custom_metadata.title_short",
      "publisher": "custom_metadata.publisher",
      "language": "custom_metadata.language",
      "genre": "custom_metadata.genre",
      "archive": "custom_metadata.archive",
      "archive_location": "custom_metadata.archive_location",
      "source": "custom_metadata.source",
      "call-number": "custom_metadata.call_number",
      "note": "user_data.notes",
      "dimensions": "custom_metadata.dimensions",
      "medium": "custom_metadata.medium"
    }
  },
  "interview": {
    "rc_type": "interview",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "URL": "custom_metadata.url",
      "ISBN": "article.isbn",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "title-short": "custom_metadata.title_short",
      "language": "custom_metadata.language",
      "archive": "custom_metadata.archive",
      "archive_location": "custom_metadata.archive_location",
      "source": "custom_metadata.source",
      "call-number": "custom_metadata.call_number",
      "note": "user_data.notes",
      "medium": "custom_metadata.medium"
    }
  },
  "article-magazine": {
    "rc_type": "magazine",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "translator": "custom_metadata.translator",
      "reviewed-author": "custom_metadata.reviewed_author",
      "container-title": "custom_metadata.container_title",
      "ISSN": "article.issn",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "page": "article.pagination",
      "issue": "article.issue",
      "volume": "article.volume",
      "title-short": "custom_metadata.title_short",
      "language": "custom_metadata.language",
      "archive": "custom_metadata.archive",
      "archive_location": "custom_metadata.archive_location",
      "source": "custom_metadata.source",
      "call-number": "custom_metadata.call_number",
      "note": "user_data.notes"
    }
  },
  "map": {
    "rc_type": "map",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "collection-editor": "custom_metadata.series_editor",
      "ISBN": "article.isbn",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "title-short": "custom_metadata.title_short",
      "collection-title": "article.journal",
      "publisher": "custom_metadata.publisher",
      "publisher-place": "custom_metadata.event_place",
      "language": "custom_metadata.language",
      "genre": "custom_metadata.genre",
      "archive": "custom_metadata.archive",
      "archive_location": "custom_metadata.archive_location",
      "source": "custom_metadata.source",
      "call-number": "custom_metadata.call_number",
      "note": "user_data.notes",
      "edition": "custom_metadata.edition",
      "scale": "custom_metadata.scale"
    }
  },
  "article-newspaper": {
    "rc_type": "newspaper_article",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "translator": "custom_metadata.translator",
      "reviewed-author": "custom_metadata.reviewed_author",
      "container-title": "custom_metadata.container_title",
      "ISSN": "article.issn",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "page": "article.pagination",
      "title-short": "custom_metadata.title_short",
      "publisher-place": "custom_metadata.event_place",
      "language": "custom_metadata.language",
      "archive": "custom_metadata.archive",
      "archive_location": "custom_metadata.archive_location",
      "source": "custom_metadata.source",
      "call-number": "custom_metadata.call_number",
      "note": "user_data.notes",
      "edition": "custom_metadata.edition",
      "section": "custom_metadata.section"
    }
  },
  "patent": {
    "rc_type": "patent",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "page": "article.pagination",
      "issue": "article.issue",
      "title-short": "custom_metadata.title_short",
      "publisher-place": "custom_metadata.event_place",
      "language": "custom_metadata.language",
      "note": "user_data.notes",
      "number": "custom_metadata.number",
      "call-number": "custom_metadata.call_number",
      "authority": "custom_metadata.authority",
      "references": "custom_metadata.references",
      "status": "custom_metadata.status"
    }
  },
  "speech": {
    "rc_type": "presentation",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "title-short": "custom_metadata.title_short",
      "publisher-place": "custom_metadata.event_place",
      "language": "custom_metadata.language",
      "genre": "custom_metadata.genre",
      "note": "user_data.notes",
      "event": "custom_metadata.event"
    }
  },
  "report": {
    "rc_type": "report",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "collection-editor": "custom_metadata.series_editor",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "page": "article.pagination",
      "title-short": "custom_metadata.title_short",
      "collection-title": "article.journal",
      "publisher": "custom_metadata.publisher",
      "publisher-place": "custom_metadata.event_place",
      "language": "custom_metadata.language",
      "genre": "custom_metadata.genre",
      "archive": "custom_metadata.archive",
      "archive_location": "custom_metadata.archive_location",
      "source": "custom_metadata.source",
      "call-number": "custom_metadata.call_number",
      "note": "user_data.notes",
      "number": "custom_metadata.number"
    }
  },
  "legislation": {
    "rc_type": "statute",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "URL": "custom_metadata.url",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "accessed": "custom_metadata.accessed",
      "page": "article.pagination",
      "volume": "article.volume",
      "title-short": "custom_metadata.title_short",
      "language": "custom_metadata.language",
      "note": "user_data.notes",
      "number": "custom_metadata.number",
      "container-title": "custom_metadata.container_title",
      "section": "custom_metadata.section",
      "chapter-number": "custom_metadata.chapter_number",
      "references": "custom_metadata.references",
    }
  },
  "thesis": {
    "rc_type": "thesis",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "publisher": "custom_metadata.publisher",
      "note": "user_data.notes",
    }
  },
  "webpage": {
    "rc_type": "webpage",
    "field_defs": {
      "title": "article.title",
      "author": "article.authors",
      "URL": "custom_metadata.url",
      "accessed": "custom_metadata.accessed",
      "translator": "custom_metadata.translator",
      "container-title": "custom_metadata.container_title",
      "abstract": "article.abstract",
      "issued": "custom_metadata.date",
      "title-short": "custom_metadata.title_short",
      "language": "custom_metadata.language",
      "genre": "custom_metadata.genre",
      "note": "user_data.notes"
    }
  }
}