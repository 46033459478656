import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// office script is loaded after angular scripts are loaded and started with execution
// because office script times out if initialize is assigned to late (meaning angular scripts load slowly)
const officeScript = window.document.createElement('script');
officeScript.src = "https://appsforoffice.microsoft.com/lib/1/hosted/office.js";
window.document.body.appendChild(officeScript);

officeScript.onload = () => {
  Office.initialize = () => {
    platformBrowserDynamic().bootstrapModule(AppModule)
      .catch(err => console.log(err));
  };  
}