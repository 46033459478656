export const SC_BIB_TITLE = 'SmartCite Bibliography'
export const SC_CIT_TITLE = 'SmartCite Citation'

export const run = <T>(
  func: (ctx: Word.RequestContext) => Promise<T>
): Promise<T> =>
  new Promise((resolve, reject) => {
    Word
      .run(async ctx => {

        // tslint:disable-next-line:no-console
        console.time('word-run')

        const result = await func(ctx)

        resolve(result)
      })
      .catch(e => {
        console.log(e)
        if (e instanceof OfficeExtension.Error) {
          console.log('Error code and message: ' + e.toString())
          console.log(e.debugInfo)
        }
        reject(e)
      })
      .finally(() => {
         // tslint:disable-next-line:no-console
         console.timeEnd('word-run')
      })
  })

  export const isDesktopRuntime = () => {
    return  window && window.top === window
  }