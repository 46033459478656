import { run, SC_BIB_TITLE, SC_CIT_TITLE } from './wordUtils'
import { recordAsItem } from '@readcube/rcp-endnote-convert'
import * as he from 'he'
import * as pr from 'fast-xml-parser'
import { CitationMeta, encodeCitationMeta, itemsAsDefaultCitationText, itemsAsRefs } from './citation'
import { ICitationOptions, IDisplayItem, IItemCitationOptions } from '@readcube/smartcite-shared/lib/models'
import * as escape from 'lodash.escape'
import { decode } from 'js-base64'

type ToRcItems = (items: any) => Promise<{ items: {[key: string]: Partial<IDisplayItem>}, addedCount: number, existingCount: number }>

export type EndnoteConversionResult = {
  isConverted: boolean
  addedCount: number
  existingCount: number
}

interface FieldToken {
  type: 'start' | 'end',
  index: number,
}

interface Field {
  start: number,
  end?: number,
  xmlSlice: string,
  fields: Field[],
  endnoteCitationType?: 'base64' | 'embedded xml' | 'biblio' | 'none',
  fieldEndnoteData?: string,
  unsyncedRcItems: any[],
  syncedRcItems: any[],
  unsyncedCitationOpts: ICitationOptions,
  syncedCitationOpts: ICitationOptions,
}

const ENDNOTE_CIT_LABEL = 'ADDIN EN.CITE'
const ENDNOTE_CIT_B64_LABEL = 'ADDIN EN.CITE.DATA'
const ENDNOTE_BIB_LABEL = 'ADDIN EN.REFLIST'

const WORD_ENDNOTE_EMBEDDED_XML_REGEXP =
  /<w:instrText.*?>(.*?)<\/w:instrText>/mig

const WORD_ENDNOTE_BASE64_REGEXP =
  /<w:fldData xml:space="preserve">([\s\S]*?)<\/w:fldData>/

const MAGIC_W_ID = 43777

const fastXmlParserOpts = {
  ignoreAttributes: false,
  attrValueProcessor: (v) => he.decode(v, { isAttributeValue: true }),
  tagValueProcessor: (v) => he.decode(v),
}

export const convertEndnote = (toRcItems: ToRcItems): Promise<EndnoteConversionResult> =>
  run(async ctx => {

    const { body } = ctx.document
    const ooxmlResult = body.getOoxml()

    const existingBiblioContentControls =
      ctx
        .document
        .contentControls
        .getByTitle(SC_BIB_TITLE)

    existingBiblioContentControls.load('item')

    await ctx.sync()

    const existingSmartciteBiblio =
      existingBiblioContentControls
      && existingBiblioContentControls.items
      && existingBiblioContentControls.items.length
      && existingBiblioContentControls.items[0]

    const { value: ooxml } = ooxmlResult

    const fields = await discoverAllEndnoteFields(ooxml)
    const { addedCount, existingCount } = await syncItems(fields, toRcItems)

    let newOoxml = '' + ooxml
    let lastId = MAGIC_W_ID

    if (!fields || !fields.length || fields.every(field => field.endnoteCitationType === 'none'))
      return { isConverted: false, addedCount: null, existingCount: null }

    for (const [index, field] of fields.entries()) {

      if (field.endnoteCitationType === 'none') {
        continue
      }

      if (field.endnoteCitationType === 'biblio') {

        if (existingSmartciteBiblio) {
          newOoxml = newOoxml.replace(field.xmlSlice, '')
          continue
        }

        const id = searchFreeId(lastId, newOoxml)
        lastId = id

        const newXmlSlice =
          contentControl({
            id,
            alias: SC_BIB_TITLE,
            tag: ' ',
            content: 'smartcite bibliography',
          })

        newOoxml = newOoxml.replace(field.xmlSlice, newXmlSlice)
        continue
      }

      const hasNoSyncedRcItems =
        !field.syncedRcItems || !field.syncedRcItems.length

      if (hasNoSyncedRcItems) {
        newOoxml = newOoxml.replace(field.xmlSlice, '')
        continue
      }

      if (['base64', 'embedded xml'].includes(field.endnoteCitationType)) {

        const citationText = itemsAsDefaultCitationText(field.syncedRcItems)
        const citationRefs = itemsAsRefs(field.syncedRcItems)
        const citationMeta: CitationMeta = {
          refs: citationRefs,
          options: field.syncedCitationOpts,
        }
        const escapedEncodedMeta = escape(encodeCitationMeta(citationMeta))

        const id = searchFreeId(lastId, newOoxml)
        lastId = id

        const newXmlSlice =
          contentControl({
            id,
            alias: SC_CIT_TITLE,
            tag: escapedEncodedMeta,
            content: citationText,
          })

        newOoxml = newOoxml.replace(field.xmlSlice, newXmlSlice)
        continue
      }
    }

    body.insertOoxml(newOoxml, 'Replace')
    await ctx.sync()

    return { isConverted: true, addedCount, existingCount }
  })

const searchFreeId = (lastId: number, ooxml: string) => {
  let id = lastId
  while (ooxml.includes(id + ''))
    id += 1

  return id
}

const discoverAllEndnoteFields = async (ooxml: string) => {

  const fieldStartRegex = /<w:fldChar w:fldCharType="begin"/g
  const fieldEndRegex = /<w:fldChar w:fldCharType="end"\/>/g

  const fieldTokens: FieldToken[] = []

  let match: RegExpExecArray
  do {
    match = fieldStartRegex.exec(ooxml)
    if (match)
      fieldTokens.push({ type: 'start', index: match.index })
  } while (match)

  do {
    match = fieldEndRegex.exec(ooxml)
    if (match)
      fieldTokens.push({ type: 'end', index: match.index })
  } while (match)

  const sortedTokens = fieldTokens.sort((a, b) => a.index - b.index)

  const fields: Field[] = []
  const fieldStack: Field[] = []

  const endLength = '<w:fldChar w:fldCharType="end"/>'.length

  for (const token of sortedTokens) {
    if (token.type === 'start') {

      const parent =
        fieldStack.length
        && fieldStack[fieldStack.length - 1]

      const field: Field = {
        start: token.index,
        end: null,
        fields: [],
        xmlSlice: '',
        unsyncedRcItems: [],
        syncedRcItems: [],
        unsyncedCitationOpts: {},
        syncedCitationOpts: {},
      }

      if (parent)
        parent.fields.push(field)
      else
        fields.push(field)

      fieldStack.push(field)
      continue
    }

    if (token.type === 'end') {
      const field = fieldStack.pop()
      field.end = token.index
      field.xmlSlice = ooxml.slice(field.start, field.end + endLength)

      if (field.xmlSlice.includes(ENDNOTE_CIT_B64_LABEL)) {

        field.endnoteCitationType = 'base64'

      } else if (field.xmlSlice.includes(ENDNOTE_CIT_LABEL)) {

        field.endnoteCitationType = 'embedded xml'
        const regex = new RegExp(WORD_ENDNOTE_EMBEDDED_XML_REGEXP)

        let embeddedData = ''
        let m: RegExpExecArray
        do {
          m = regex.exec(field.xmlSlice)
          if (m)
            embeddedData += m[1]
        } while (m)

        field.fieldEndnoteData =
          new DOMParser()
            .parseFromString(embeddedData, 'text/html')
            .documentElement
            .textContent
            .replace('ADDIN EN.CITE', '')
            .trim()

        const { rcItems, citationOptions } = rcItemsAndCitationOptionsFromFieldEndnoteData(field.fieldEndnoteData)
        field.unsyncedRcItems = rcItems
        field.unsyncedCitationOpts = citationOptions

      } else if (field.xmlSlice.includes(ENDNOTE_BIB_LABEL))
        field.endnoteCitationType = 'biblio'
      else
        field.endnoteCitationType = 'none'

      continue
    }
  }

  fields
    .filter(f => f.endnoteCitationType === 'base64')
    .forEach(field => {

      const allChildEmbeddedData =
        field
          .fields
          .filter(c => c.endnoteCitationType === 'base64')
          .map(child => {
            const childMatch = child.xmlSlice.match(WORD_ENDNOTE_BASE64_REGEXP) && child.xmlSlice.match(WORD_ENDNOTE_BASE64_REGEXP)[1]

            if (!childMatch)
              return ''

            const embeddedData = childMatch.replace(/\r\n/g, '')
            const decoded = decode(embeddedData)
            return decoded
          })
          .filter(e => e)
          .join('')

      field.fieldEndnoteData = allChildEmbeddedData
      const { rcItems, citationOptions } = rcItemsAndCitationOptionsFromFieldEndnoteData(field.fieldEndnoteData)
      field.unsyncedRcItems = rcItems
      field.unsyncedCitationOpts = citationOptions
    })

  return fields
}

const rcItemsAndCitationOptionsFromFieldEndnoteData =
  (fieldEndnoteData: string): { rcItems: any, citationOptions: ICitationOptions } => {
    const citationOptions: ICitationOptions = { items: {} }
    const parsed = pr.parse(fieldEndnoteData, fastXmlParserOpts)

    if (!parsed?.EndNote?.Cite)
      return { rcItems: [], citationOptions }

    const xmlEndNoteRecords =
      Array.isArray(parsed.EndNote.Cite)
        ? parsed.EndNote.Cite
        : [parsed.EndNote.Cite]

    const rcItems =
      xmlEndNoteRecords
        .reduce((items, c) => {

          const item =
            c.record
            && recordAsItem('smartcite', c.record, [])?.item

          if (!item)
            return items

          const opts: IItemCitationOptions = {}

          if (c.Prefix)
            opts.prefix = c.Prefix

          if (c.Suffix)
            opts.suffix = c.Suffix

          if (c.Pages)
            opts.position = { name: 'Page', value: c.Pages }

          if (opts.suffix || opts.prefix || opts.position)
            citationOptions.items[item.import_data.original_id] = opts

          const itemWithMatchinOriginalIdExists =
            items.findIndex(ci => ci.import_data.original_id === item.import_data.original_id) != -1

          if (!itemWithMatchinOriginalIdExists)
            items.push(item)

          return items
        }, [])
        .filter(i => i)

    return { rcItems, citationOptions }
  }

const syncItems = async (fields: Field[], toRcItems: ToRcItems): Promise<{ addedCount: number, existingCount: number }> => {
  const unsyncedByOriginalId =
    fields
      .reduce((unsynced, field) => {

        field
          .unsyncedRcItems
          .forEach(item => {
            if (!unsynced[item.import_data.original_id])
              unsynced[item.import_data.original_id] = item
          })

        return unsynced
      }, {})

  const unsyncedRcItems = Object.values(unsyncedByOriginalId)

  const { items: syncedItemsByOriginalId, addedCount, existingCount } = await toRcItems(unsyncedRcItems)

  fields.forEach(field => {

    field.syncedRcItems =
      field
        .unsyncedRcItems
        .reduce((items, i) => {

          const item = syncedItemsByOriginalId[i.import_data.original_id]

          const matchingItemWithRcIdExists =
            items.findIndex(ci => ci.id === item.id) != -1

          if (!matchingItemWithRcIdExists)
            items.push(item)

          return items
        }, [])
        .filter(i => i)

    field.syncedCitationOpts = field.unsyncedCitationOpts
    field.syncedCitationOpts.items =
      field.syncedCitationOpts.items
      && Object.entries(field.syncedCitationOpts.items)
        .reduce((newItems, entry) => {

          const [originalId, opts] = entry
          const syncedItem = syncedItemsByOriginalId[originalId]

          if (!syncedItem)
            return newItems

          const ref = syncedItem.collection_id + ':' + syncedItem.id
          newItems[ref] = opts
          return newItems
        }, {})
  })

  return {
    addedCount,
    existingCount
  }
}

const contentControl = (
  { id, alias, tag, content }: {
    id: number,
    alias: string,
    tag: string,
    content: string,
  }) =>
  `
<w:sdt>
  <w:sdtPr>
    <w:alias w:val="${alias}"/>
    <w:tag w:val="${tag}"/>
    <w:id w:val="${id}"/>
  </w:sdtPr>
  <w:sdtContent>
    <w:r>
      <w:t>${content}</w:t>
    </w:r>
  </w:sdtContent>
</w:sdt>
`
