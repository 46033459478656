
/**
 *  We have two approaches when trying to identify references in citation.
 *  For numeric styles we'll try to parse the identifiers and hyperlink them:
 *
 *  (5) -> (<a href="#reference_bookmark">5</a>)
 *
 *  For other styles we inject additional tags to csl so that the final citation
 *  has additional ref id tags:
 *
 *  (Hawking, 1966) -> ( RC_REF_START Hawking, 1966 RC_REF_ID_START ba0b1cff-74bc-4d1f-8240-579d44f121b7 RC_REF_ID_END )
 *
 *  we then parse the tags and create hyperlinks
 *
 *    ( RC_REF_START Hawking, 1966 RC_REF_ID_START ba0b1cff-74bc-4d1f-8240-579d44f121b7 RC_REF_ID_END )
 *
 *  transforms into ->
 *
 *    (<a href="#reference_bookmark">Hawking, 1966</a>)
 *
 */
type RefIdentApporach =
  "inject_rc_identifiers_to_citation"
  | "parse_numeric_citation"

type StyleArg = { style: any }
type RcRefsTransform =
  ({ citationHtml, bookmarks }: { citationHtml: string, bookmarks: Record<string, string> }) => string

type NumericTransform =
  ({ citationHtml, biblioRefs }: { citationHtml: string, biblioRefs: string[], bookmarks: Record<string, string> }) => string

const ERROR_NO_CITATION_LAYOUT_TAG =
  `No citation layout tag found.`

const RC_REF_START_TOKEN    = "RC_REF_START"
const RC_REF_ID_START_TOKEN = "RC_REF_ID_START"
const RC_REF_ID_END_TOKEN   = "RC_REF_ID_END"

export const chooseIdentApproach = ({ style }: StyleArg): RefIdentApporach =>
  style.includes(`category citation-format="numeric"`)
    ? "parse_numeric_citation"
    : "inject_rc_identifiers_to_citation"

export const injectRcTagsToStyle = ({ style }: StyleArg): string => {

  const domParser = new DOMParser()
  const xmlDoc    = domParser.parseFromString(style, "text/xml")

  const citationLayoutTag = xmlDoc.querySelector("citation layout")

  if (!citationLayoutTag)
    throw new Error(ERROR_NO_CITATION_LAYOUT_TAG)

  const rcRefStartElem =
    elemWithAtt({ xmlDoc, tagName: "text", attName: "value", attValue: RC_REF_START_TOKEN })

  citationLayoutTag.insertBefore(rcRefStartElem, citationLayoutTag.childNodes[0])

  const rcRefIdStartElem =
    elemWithAtt({ xmlDoc, tagName: "text", attName: "value", attValue: RC_REF_ID_START_TOKEN })

  const rcRefIdElem =
    elemWithAtt({ xmlDoc, tagName: "text", attName: "variable", attValue: "id" })

  const rcRefIdEndElem =
    elemWithAtt({ xmlDoc, tagName: "text", attName: "value", attValue: RC_REF_ID_END_TOKEN })

  citationLayoutTag.appendChild(rcRefIdStartElem)
  citationLayoutTag.appendChild(rcRefIdElem)
  citationLayoutTag.appendChild(rcRefIdEndElem)

  const serializer = new XMLSerializer()
  return serializer.serializeToString(xmlDoc)
}

const elemWithAtt = (
  { xmlDoc, tagName, attName, attValue }:
  { xmlDoc: Document, tagName: string, attName: string, attValue: string }
) => {
  const element = xmlDoc.createElement(tagName)
  element.setAttribute(attName, attValue)
  return element
}

export const transformRcIdentsToHyperlinks: RcRefsTransform = ({ citationHtml, bookmarks }) => {

  const rcCitationRegex =
    new RegExp(`(${RC_REF_START_TOKEN}.+?${RC_REF_ID_END_TOKEN})`, "g")

  let rcRefs: RegExpExecArray[] = []
  let rcRef = rcCitationRegex.exec(citationHtml)

  while (rcRef) {
    rcRefs.push(rcRef)
    rcRef = rcCitationRegex.exec(citationHtml)
  }

  for (const ref of rcRefs) {

    const refRegex =
      new RegExp(`${RC_REF_START_TOKEN}(?<refContent>.+)?${RC_REF_ID_START_TOKEN}(?<refId>.+)${RC_REF_ID_END_TOKEN}`)

    const originalContent = ref[0]
    const refRegExpResult = refRegex.exec(originalContent)
    const { refContent, refId } = refRegExpResult.groups

    const bookmark = bookmarks[refId]
    const hrefContent = `<a href="#${bookmark}">${refContent || ""}</a>`

    citationHtml = citationHtml.replace(originalContent, hrefContent)
  }

  return citationHtml
}

export const transformNumericIdentsToHyperlinks: NumericTransform = ({ citationHtml, biblioRefs, bookmarks }) => {

  const numsRegExp = /\d+(-\d+)?/g

  const nums: RegExpExecArray[] = []
  let num = numsRegExp.exec(citationHtml)

  while (num) {
    nums.push(num)
    num = numsRegExp.exec(citationHtml)
  }

  nums.reverse()

  for (num of nums) {

    const originalContent = num[0]
    const transformedContent =
      originalContent
        .split('-')
        .map(text => {

          const refNo = +(text.trim())
          const ref = biblioRefs[refNo - 1]

          if (!ref)
            return text

          const bookmark = bookmarks[ref]

          if (!bookmark)
            return text

          return `<a href="#${bookmark}">${text}</a>`
        })
        .join('-')

    const preCit  = citationHtml.slice(0, num.index)
    const postCit = citationHtml.slice(num.index + num[0].length, citationHtml.length)

    citationHtml = preCit + transformedContent + postCit
  }

  return citationHtml
}
