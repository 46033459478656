// In some versions of winword (those that use IE) citeproc processing can take a bit longer which
// will cause winword to restart the addon as it will consider the addon unresponsive.
// This does not occur on other platforms (macword, other winword, onlineword).
// We push the processing to webworker.
export const bgBiblioProcess: any = (workerArgs) => {
  console.log('Starting background biblio/citation generation');

  return new Promise((res, rej) => {

    if (typeof Worker !== 'undefined') {
      // Create a new
      const worker = new Worker(new URL('../../bgCiteproc.worker', import.meta.url), {
        type: 'module'
      });
      worker.onmessage = e => {
        res(e.data);
      };

      worker.postMessage(workerArgs);
    } else {
      console.log('Web Workers are not supported in this environment.');
      // Web Workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
    }
  });
};
