import { run } from './wordUtils'
import { SC_CIT_TITLE, SC_BIB_TITLE } from './wordUtils'

export const isSelectionParentBibliographyOldApi = async () => {
  let selectionParentIsBibliography = false

  try {
    selectionParentIsBibliography =
      await run(async ctx => {
        const range =
          ctx
            .document
            .getSelection()

        if (!range)
          return

        range.parentContentControl.load('title')
        await ctx.sync()

        const { title } = range.parentContentControl
        return title === SC_BIB_TITLE
    })
  } catch (e) {
    /* disregard (parentContentControl will fail when null in api 1.1) */
  }

  return selectionParentIsBibliography
}

export const getCitationControlsFromSelectionOldApi = async (
  ctx: Word.RequestContext
): Promise<Word.ContentControl | undefined> => {

  let parentContentControlId

  try {
    parentContentControlId = await run(async innerCtx => {

      const innerRange =
        innerCtx
          .document
          .getSelection()

      if (!innerRange)
        return

      innerRange
        .parentContentControl
        .load('id')

      await innerCtx.sync()

      return innerRange.parentContentControl.id
    })
  } catch (e) {
    /* disregard */
  }

  if (parentContentControlId) {

    const parentContentControl =
      ctx
        .document
        .contentControls
        .getById(parentContentControlId)

    parentContentControl.load('id, title, tag, font/*')

    await ctx.sync()

    if (parentContentControl?.title === SC_CIT_TITLE)
      return parentContentControl
  }

  const range = ctx.document.getSelection()
  if (!range)
    return

  const innerContentControls =
    range
      .contentControls
      .getByTitle(SC_CIT_TITLE)

  if (!innerContentControls)
    return

  innerContentControls.load('item')

  await ctx.sync()

  const hasFirstInnerContentControl =
    innerContentControls.items
      && innerContentControls.items.length
      && innerContentControls.items[0]

  if (!hasFirstInnerContentControl)
    return

  const scContentControl = innerContentControls.items[0]
  scContentControl.load('id, title, tag, font/*')
  await ctx.sync()
  return scContentControl
}
