
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ConfigService } from './services/configService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {

  constructor(
    public configService: ConfigService
  ) { }
}
