import { run, SC_BIB_TITLE, SC_CIT_TITLE } from './wordUtils';
import { CitationMeta, encodeCitationMeta, itemsAsDefaultCitationText, itemsAsRefs } from './citation';
import { ICitationOptions, IDisplayItem, IItem } from '@readcube/smartcite-shared/lib/models';
import * as escape from 'lodash.escape';
import { decode } from 'js-base64';
import { CiteprocToRcMap } from './citeprocToRcMap';

type ToRcItems = (items: any) => Promise<{ items: { [key: string]: Partial<IDisplayItem>; }, addedCount: number, existingCount: number; }>;

export type QuosaConversionResult = {
  isConverted: boolean;
  addedCount: number;
  existingCount: number;
};

interface FieldToken {
  type: 'start' | 'end',
  index: number,
}

interface Field {
  start: number,
  end?: number,
  xmlSlice: string,
  fields: Field[],
  quosaCitationType?: 'base64' | 'embedded xml' | 'biblio' | 'none',
  fieldQuosaData?: {
    citations: QuosaInlineCitation[];
  },
  unsyncedRcItems: any[],
  syncedRcItems: any[],
  unsyncedCitationOpts: ICitationOptions,
  syncedCitationOpts: ICitationOptions,
}

interface QuosaInlineCitation {
  guid: string;
  meta: {
    AUID?: string;
    URL?: string;
    DOI?: string;
    PMID?: string;
    author?:
    {
      family: string;
      given: string;
    }[];
    WEB?: string;
    accessed?: {
      raw?: string;
    };
    'collection-number'?: string;
    'container-title'?: string;
    issue?: string;
    issued?: {
      raw?: string;
    };
    journalAbbreviation?: string;
    jurisdiction?: string;
    page?: string;
    'publisher-place'?: string;
    title?: string;
    type?: string;
    volume?: string;
  };
  vls: any;
}

// citation labels (as found in different quosa docs)
const QUOSA_CIT_LABEL = 'ADDIN QX.CITE';
const QUOSA_CIT_LABEL_M = 'ADDIN QLM.CITE';
// todo check if quosa base64 exists
// const QUOSA_CIT_B64_LABEL = 'ADDIN QX.CITE.DATA';

// bib labels (as found in different quosa docs)
const QUOSA_BIB_LABEL = 'ADDIN QX.BIBL';
const QUOSA_BIB_LABEL_M = 'ADDIN QLM.BIBL';

const QUOSA_BIB_TITLE = 'Reference List';

const WORD_QUOSA_EMBEDDED_XML_REGEXP =
  /<w:instrText.*?>(.*?)<\/w:instrText>/mig;

const WORD_QUOSA_BASE64_REGEXP =
  /<w:fldData xml:space="preserve">([\s\S]*?)<\/w:fldData>/;

const MAGIC_W_ID = 43777;

export const convertQuosa = (toRcItems: ToRcItems): Promise<QuosaConversionResult> =>
  run(async ctx => {

    const { body } = ctx.document;
    const ooxmlResult = body.getOoxml();

    const existingBiblioContentControls =
      ctx
        .document
        .contentControls
        .getByTitle(SC_BIB_TITLE);

    existingBiblioContentControls.load('item');

    await ctx.sync();

    const existingSmartciteBiblio =
      existingBiblioContentControls
      && existingBiblioContentControls.items
      && existingBiblioContentControls.items.length
      && existingBiblioContentControls.items[0];

    const { value: ooxml } = ooxmlResult;

    const fields = await discoverAllQuosaFields(ooxml);

    const { addedCount, existingCount } = await syncItems(fields, toRcItems);

    let newOoxml = '' + ooxml;
    let lastId = MAGIC_W_ID;

    if (!fields || !fields.length || fields.every(field => field.quosaCitationType === 'none'))
      return { isConverted: false, addedCount: null, existingCount: null };

    for (const [index, field] of fields.entries()) {

      if (field.quosaCitationType === 'none') {
        continue;
      }

      if (field.quosaCitationType === 'biblio') {

        if (existingSmartciteBiblio) {
          newOoxml = newOoxml.replace(field.xmlSlice, '');
          continue;
        }

        const id = searchFreeId(lastId, newOoxml);
        lastId = id;

        const newXmlSlice =
          contentControl({
            id,
            alias: SC_BIB_TITLE,
            tag: ' ',
            content: 'smartcite bibliography',
          });

        newOoxml = newOoxml.replace(field.xmlSlice, newXmlSlice);
        continue;
      }

      const hasNoSyncedRcItems =
        !field.syncedRcItems || !field.syncedRcItems.length;

      if (hasNoSyncedRcItems) {
        newOoxml = newOoxml.replace(field.xmlSlice, '');
        continue;
      }

      if (['base64', 'embedded xml'].includes(field.quosaCitationType)) {

        const citationText = itemsAsDefaultCitationText(field.syncedRcItems);
        const citationRefs = itemsAsRefs(field.syncedRcItems);
        const citationMeta: CitationMeta = {
          refs: citationRefs,
          options: field.syncedCitationOpts,
        };
        const escapedEncodedMeta = escape(encodeCitationMeta(citationMeta));

        const id = searchFreeId(lastId, newOoxml);
        lastId = id;

        const newXmlSlice =
          contentControl({
            id,
            alias: SC_CIT_TITLE,
            tag: escapedEncodedMeta,
            content: citationText,
          });

        newOoxml = newOoxml.replace(field.xmlSlice, newXmlSlice);
        continue;
      }
    }

    newOoxml = newOoxml.replace(QUOSA_BIB_TITLE, '');

    body.insertOoxml(newOoxml, 'Replace');
    await ctx.sync();

    return { isConverted: true, addedCount, existingCount };
  });

const searchFreeId = (lastId: number, ooxml: string) => {
  let id = lastId;
  while (ooxml.includes(id + ''))
    id += 1;

  return id;
};

const discoverAllQuosaFields = async (ooxml: string) => {

  const fieldStartRegex = /<w:fldChar w:fldCharType="begin"/g;
  const fieldEndRegex = /<w:fldChar w:fldCharType="end"\/>/g;

  const fieldTokens: FieldToken[] = [];

  let match: RegExpExecArray;
  do {
    match = fieldStartRegex.exec(ooxml);
    if (match)
      fieldTokens.push({ type: 'start', index: match.index });
  } while (match);

  do {
    match = fieldEndRegex.exec(ooxml);
    if (match)
      fieldTokens.push({ type: 'end', index: match.index });
  } while (match);

  const sortedTokens = fieldTokens.sort((a, b) => a.index - b.index);

  const fields: Field[] = [];
  const fieldStack: Field[] = [];

  const endLength = '<w:fldChar w:fldCharType="end"/>'.length;

  for (const token of sortedTokens) {
    if (token.type === 'start') {

      const parent =
        fieldStack.length
        && fieldStack[fieldStack.length - 1];

      const field: Field = {
        start: token.index,
        end: null,
        fields: [],
        xmlSlice: '',
        unsyncedRcItems: [],
        syncedRcItems: [],
        unsyncedCitationOpts: {},
        syncedCitationOpts: {},
      };

      if (parent)
        parent.fields.push(field);
      else
        fields.push(field);

      fieldStack.push(field);
      continue;
    }

    if (token.type === 'end') {
      const field = fieldStack.pop();
      field.end = token.index;
      field.xmlSlice = ooxml.slice(field.start, field.end + endLength);

      // todo check if there is Quosa Base 64 record if needed
      // if (field.xmlSlice.includes(QUOSA_CIT_B64_LABEL)) {
      //   field.quosaCitationType = 'base64';
      // }

      if (field.xmlSlice.includes(QUOSA_CIT_LABEL) || field.xmlSlice.includes(QUOSA_CIT_LABEL_M)) {

        field.quosaCitationType = 'embedded xml';
        const regex = new RegExp(WORD_QUOSA_EMBEDDED_XML_REGEXP);

        let embeddedData = '';
        let m: RegExpExecArray;
        do {
          m = regex.exec(field.xmlSlice);
          if (m)
            embeddedData += m[1];
        } while (m);

        field.fieldQuosaData = JSON.parse(embeddedData.replace(QUOSA_CIT_LABEL, '').replace(QUOSA_CIT_LABEL_M, ''));

        const { rcItems, citationOptions } = rcItemsAndCitationOptionsFromFieldQuosaData(field.fieldQuosaData?.citations);

        field.unsyncedRcItems = rcItems;
        field.unsyncedCitationOpts = citationOptions;

      } else if (field.xmlSlice.includes(QUOSA_BIB_LABEL) || field.xmlSlice.includes(QUOSA_BIB_LABEL_M))
        field.quosaCitationType = 'biblio';
      else
        field.quosaCitationType = 'none';

      continue;
    }
  }

  return fields;
};


const rcItemsAndCitationOptionsFromFieldQuosaData =
  (quosaCitations: QuosaInlineCitation[]): { rcItems: any, citationOptions: ICitationOptions; } => {
    const citationOptions: ICitationOptions = { items: {} };

    if (!quosaCitations?.length)
      return { rcItems: [], citationOptions };

    const rcItems: Partial<IItem>[] =
      quosaCitations.map(item => {
        return mapCiteprocItemToRcItem(item);
      });

    return { rcItems, citationOptions };
  };

const mapCiteprocItemToRcItem = item => {
  // sometimes the meta subobject is double stringified, so it needs to be double parsed
  if (typeof item.meta === 'string')
    item.meta = JSON.parse(item.meta);

  const map = CiteprocToRcMap[item.meta?.type] || CiteprocToRcMap['article-journal'];

  const rcItem: any = {};

  rcItem.item_type = map.rc_type;
  rcItem.import_data = {
    // sometimes a guid is present in the xml, sometimes there is an uuid
    original_id: item.guid || item.uuid,
    imported_by: 'smartcite',
    source: 'quosa'
  };

  for (const key in map.field_defs) {
    const citeprocValue = item.meta[key];

    const rcPropStrings = map.field_defs[key].split('.');

    if (!rcItem[rcPropStrings[0]])
      rcItem[rcPropStrings[0]] = {};

    if (key === 'author' && citeprocValue)
      rcItem[rcPropStrings[0]][rcPropStrings[1]] = getAuthors(citeprocValue);

    else if (['issued', 'accessed'].includes(key) && citeprocValue) {
      const { year, date } = getDateFromCiteproc(citeprocValue?.raw);
      if (date)
        rcItem[rcPropStrings[0]][rcPropStrings[1]] = date;

      if (key === 'issued' && year)
        rcItem.article.year = year;
    }

    else if (citeprocValue)
      rcItem[rcPropStrings[0]][rcPropStrings[1]] = citeprocValue;
  }

  return rcItem;
};

const getDateFromCiteproc = (val): { year: string, date: string; } => {
  if (!val)
    return {
      year: null,
      date: null
    };

  val = val.replace(/\s+/g, '');

  if (/^\d{1,4}$/.test(val) && val > 0)
    return {
      year: val,
      date: val
    };

  let dateParts = /^(\d{1,4})-(\d{1,2})$/.exec(val);

  const hasYearMonth =
    dateParts
    && dateParts[1] && (parseInt(dateParts[1]) > 0)
    && dateParts[2] && (parseInt(dateParts[2]) > 0);

  if (hasYearMonth)
    return {
      year: dateParts[1],
      date: val
    };

  dateParts = /^(\d{1,4})-(\d{1,2})-(\d{1,2})$/.exec(val);

  const hasYearMonthDate =
    dateParts
    && dateParts[1] && (parseInt(dateParts[1]) > 0)
    && dateParts[2] && (parseInt(dateParts[2]) > 0)
    && dateParts[3] && (parseInt(dateParts[3]) > 0);

  if (hasYearMonthDate)
    return {
      year: dateParts[1],
      date: val
    };

  dateParts = /^(\d{1,2})([a-zA-Z]{3})(\d{4})$/.exec(val);

  const hasDateMonthYearWithChars =
    dateParts
    && dateParts[1]
    && dateParts[2]
    && dateParts[3];

  if (hasDateMonthYearWithChars) {
    return {
      year: dateParts[3],
      date: dateParts[3] + '-' + mapMonthChars[dateParts[2].toUpperCase()] + '-' + dateParts[1]
    };
  }

  dateParts = /^(\d{4})([a-zA-Z]{3})(\d{1,2})$/.exec(val);

  const hasYearMonthYearWithChars =
    dateParts
    && dateParts[1]
    && dateParts[2]
    && dateParts[3];

  if (hasYearMonthYearWithChars) {
    return {
      year: dateParts[1],
      date: dateParts[1] + '-' + mapMonthChars[dateParts[2].toUpperCase()] + '-' + dateParts[3]
    };
  }

  return {
    year: null,
    date: null
  };
};

const mapMonthChars = {
  'JAN': '01',
  'FEB': '02',
  'MAR': '03',
  'APR': '04',
  'MAY': '05',
  'JUN': '06',
  'JUL': '07',
  'AUG': '08',
  'SEP': '09',
  'OCT': '10',
  'NOV': '11',
  'DEC': '12'
};

const getAuthors = (authors: { family: string; given: string; }[]): string[] => {
  if (!authors)
    return [];
  return authors.map(author => author.given + ' ' + author.family);
};

const syncItems = async (fields: Field[], toRcItems: ToRcItems): Promise<{ addedCount: number, existingCount: number; }> => {
  const unsyncedByOriginalId =
    fields
      .reduce((unsynced, field) => {

        field
          .unsyncedRcItems
          .forEach(item => {
            if (!unsynced[item.import_data.original_id])
              unsynced[item.import_data.original_id] = item;
          });

        return unsynced;
      }, {});

  const unsyncedRcItems = Object.values(unsyncedByOriginalId);

  const { items: syncedItemsByOriginalId, addedCount, existingCount } = await toRcItems(unsyncedRcItems);

  fields.forEach(field => {

    field.syncedRcItems =
      field
        .unsyncedRcItems
        .reduce((items, i) => {

          const item = syncedItemsByOriginalId[i.import_data.original_id];

          const matchingItemWithRcIdExists =
            items.findIndex(ci => ci.id === item.id) != -1;

          if (!matchingItemWithRcIdExists)
            items.push(item);

          return items;
        }, [])
        .filter(i => i);

    field.syncedCitationOpts = field.unsyncedCitationOpts;
    field.syncedCitationOpts.items =
      field.syncedCitationOpts.items
      && Object.entries(field.syncedCitationOpts.items)
        .reduce((newItems, entry) => {

          const [originalId, opts] = entry;
          const syncedItem = syncedItemsByOriginalId[originalId];

          if (!syncedItem)
            return newItems;

          const ref = syncedItem.collection_id + ':' + syncedItem.id;
          newItems[ref] = opts;
          return newItems;
        }, {});
  });

  return {
    addedCount,
    existingCount
  };
};

const contentControl = (
  { id, alias, tag, content }: {
    id: number,
    alias: string,
    tag: string,
    content: string,
  }) =>
  `
<w:sdt>
  <w:sdtPr>
    <w:alias w:val="${alias}"/>
    <w:tag w:val="${tag}"/>
    <w:id w:val="${id}"/>
  </w:sdtPr>
  <w:sdtContent>
    <w:r>
      <w:t>${content}</w:t>
    </w:r>
  </w:sdtContent>
</w:sdt>
`;
